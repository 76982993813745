<template>
  <v-card flat>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12">
          <allowed-plates :plates="userPlatesData"></allowed-plates>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import * as API from "@/api/api";
import AllowedPlates from "../components/zone/AllowedPlates.vue";
import { EventBus } from "@/lib/EventBus";
import { mapGetters } from "vuex";
export default {
  name: "AllowedPlatesView",
  components: { AllowedPlates },
  data() {
    return {
      userPlatesData: [],
    };
  },
  computed:{
    ...mapGetters({
      selectedZone: "pinaStore/getterSelectedZone"
    }),
  },
  mounted() {
    this.fetchUserPlateList();
    EventBus.$on("zone-changed", () => {
      this.fetchUserPlateList();
    });
    EventBus.$on("user-plate-deleted", () => {
      this.fetchUserPlateList();
    });
    EventBus.$on("white-list-user-added", () => {
      this.fetchUserPlateList();
    });
    
  },
  methods: {
    async fetchUserPlateList() {
      let allowedPlatesData = this.selectedZone?.zid
        ? await API.getWhiteListUsers({ zid: this.selectedZone.zid })
        : {};
      this.userPlatesData = allowedPlatesData?.data?.data
        ? allowedPlatesData.data.data
        : [];
    },
  },
};
</script>
