<template>
  <v-card flat>
    <v-card-title class="pa-0">
      <v-container fluid pt-2>
        <v-row no-gutters class="justify-end">
          <v-spacer></v-spacer>
          <v-col cols="3" class="text-end pr-3">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  class="elevation-0"
                  v-bind="attrs"
                  v-on="on"
                  @click="addWhiteListUserDialog = true"
                >
                  <v-icon color="primary"> person_add </v-icon>
                </v-btn>
              </template>
              <span>Add whitelist user</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row no-gutters class="justify-end">
          <v-col class="align-self-center">
            <span>WhiteListed Users</span>
          </v-col>
          <v-col cols="3" class="text-end">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              hide-details
              outlined
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-container fluid px-0 pa-1>
      <v-row no-gutters>
        <v-col cols="12">
          <v-data-table
            :headers="plateHeaders"
            :items="plates"
            :search="search"
            class="pa-2"
          >
            <template v-slot:[`item.email`]="{ item }">
              {{ item.email == "" ? "-" : item.email }}
            </template>
            <template v-slot:[`item.contactNumber`]="{ item }">
              {{ item.contactNumber == "" ? "-" : item.contactNumber }}
            </template>
            <template v-slot:[`item.licensePlate`]="{ item }">
              {{
                item.licensePlate.regNo == "" ? "-" : item.licensePlate.regNo
              }}
            </template>
            <template v-slot:[`item.state`]="{ item }">
              {{
                item.licensePlate.state == "" ? "-" : item.licensePlate.state
              }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    medium
                    v-on="on"
                    @click="openConfirmDelPlateDialog(item)"
                  >
                    delete
                  </v-icon>
                </template>
                <span>Delete user plate</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="confirmDelPlateDialog" persistent max-width="390">
      <v-card>
        <v-card-title class="headline"> Confirmation </v-card-title>
        <v-card-text>Are you sure you want to delete record ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDelPlateDialog = false"> Cancel </v-btn>
          <v-btn
            color="primary"
            class="elevation-0"
            @click="deleteUserPlate(item)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addWhiteListUserDialog" max-width="600">
      <v-card style="background-color: #f4f6f9">
        <v-card-title class="headline text-center">
          <span>Create New Whitelist Account</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center pt-3">
          <v-container fluid pa-0>
            <v-row no-gutters class="px-2">
              <v-col cols="2" class="text-left align-self-center">
                <span>Zone</span>
              </v-col>
              <v-col>
                <v-select
                  solo
                  flat
                  v-click-outside="onClickOutside"
                  v-model="zoneSelected"
                  :items="filterZones"
                  :menu-props="{ offsetY: true }"
                  label="Select Zone"
                  :item-text="getZoneText"
                  item-value="zid"
                  hide-details
                  single-line
                >
                  <template v-slot:prepend-item>
                    <v-text-field
                      v-model="filterZoneText"
                      class="py-0"
                      autofocus
                      hide-details
                      clearable
                      @click:clear="onFilterZoneTextClear"
                    ></v-text-field>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-1">
              <v-col col="12" class="text-left">
                <v-btn-toggle
                  v-model="userType"
                  tile
                  color="primary"
                  class="text--darken-2"
                  group
                >
                  <v-btn value="VIP"> VIP </v-btn>
                  <v-btn value="Reservation"> Reservation </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col col="6" class="px-1">
                <divrt-datetime-picker
                  :date-picker-props="startDatePickerProps"
                  :time-picker-props="startTimePickerProps"
                  v-model="startDatetime"
                  label="Select Start Date"
                  :menu-props="startDatetimeMenuProps"
                  :text-field-props="startDatetimeInputProps"
                ></divrt-datetime-picker>
              </v-col>
              <v-col col="6" class="px-1">
                <divrt-datetime-picker
                  :date-picker-props="endDatePickerProps"
                  :time-picker-props="endTimePickerProps"
                  v-model="endDatetime"
                  :disabled="!(userType == 'Reservation')"
                  label="Select End Date"
                  :menu-props="endDatetimeMenuProps"
                  :text-field-props="endDatetimeInputProps"
                ></divrt-datetime-picker>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-1">
              <v-col cols="12" class="text-left align-self-center">
                <v-checkbox
                  v-model="licencePlateCB"
                  hide-details
                  label="Licence Plate"
                ></v-checkbox>
              </v-col>
              <v-col cols="3" class="py-1 pr-1" v-show="licencePlateCB"
                ><v-text-field
                  v-model="licencePlateStateValue"
                  label="State"
                  hide-details
                  solo
                  flat
                  clearable
                ></v-text-field
              ></v-col>
              <v-col cols="9" class="py-1" v-show="licencePlateCB"
                ><v-text-field
                  v-model="licencePlateValue"
                  label="Plate"
                  hide-details
                  flat
                  solo
                  clearable
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row no-gutters class="px-1">
              <v-col cols="12" class="text-left align-self-center">
                <v-checkbox
                  v-model="phoneNumberCB"
                  hide-details
                  label="Phone number"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" class="py-1" v-show="phoneNumberCB"
                ><v-text-field
                  v-model="phoneNumberValue"
                  label="Contact Number"
                  hide-details
                  flat
                  solo
                  clearable
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row no-gutters class="px-1">
              <v-col cols="12" class="text-left align-self-center">
                <v-checkbox
                  v-model="emailCB"
                  label="Email"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="12" class="py-1" v-show="emailCB"
                ><v-text-field
                  v-model="emailValue"
                  label="Email"
                  hide-details
                  flat
                  solo
                  clearable
                ></v-text-field
              ></v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn text @click="addWhiteListUserDialog = false">Cancel</v-btn>
          <v-btn
            color="primary"
            class="elevation-0"
            :loading="addWhiteListUserBtnLoading"
            :disabled="isAddWhiteListUserBtnDisabled"
            @click="addWhiteListUser()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-snackbar v-model="deleteUserPlateSnackBar">
      {{ deleteUserStatusMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="deleteUserPlateSnackBar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar> -->
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import * as API from "@/api/api";
import DivrtDatetimePicker from "@/uiComponents/DateTimePicker.vue";
import { format } from "date-fns";
import { EventBus } from "@/lib/EventBus";
export default {
  name: "AllowedPlates",
  components: { DivrtDatetimePicker },
  props: {
    plates: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      licencePlateValue: "",
      licencePlateStateValue: "",
      phoneNumberValue: "",
      emailValue: "",
      userType: "",
      addWhiteListUserDialog: false,
      search: "",
      licencePlateCB: false,
      phoneNumberCB: false,
      emailCB: false,
      startDatetime: null,
      startDatePickerProps: {},
      startTimePickerProps: {},
      startDatetimeInputProps: { solo: true, flat: true },
      startDatetimeMenuProps: {
        transition: "scale-transition",
        "offset-y": true,
        closeOnContentClick: false,
        bottom: true,
      },
      endDatetime: null,
      endDatePickerProps: {},
      endTimePickerProps: {},
      endDatetimeInputProps: { solo: true, flat: true },
      endDatetimeMenuProps: {
        transition: "scale-transition",
        "offset-y": true,
        closeOnContentClick: false,
        bottom: true,
      },
      filterZoneText: "",
      zoneSelected: "",
      plateHeaders: [
        { text: "Email", value: "email", align: "center" },
        { text: "Order Type", value: "orderType", align: "center" },
        { text: "Licence Plate", value: "licensePlate", align: "center" },
        { text: "State", value: "state", align: "center" },
        { text: "Contact No", value: "contactNumber", align: "center" },
        { text: "Start Date", value: "startDate", align: "center" },
        { text: "End Date", value: "startDate", align: "center" },
        { text: "Actions", value: "actions", align: "center", width: "5%" },
      ],
      selectedPlateToDel: {},
      confirmDelPlateDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedZone: "pinaStore/getterSelectedZone",
      uid: "authStore/getterUID",
      zones: "pinaStore/getterZones",
    }),
    filterZones() {
      if (this.filterZoneText == "" || this.filterZoneText == null) {
        return this.zones;
      }
      return this.zones.filter(
        (zone) =>
          zone.name.toLowerCase().includes(this.filterZoneText.toLowerCase()) ||
          zone.zcode.includes(this.filterZoneText)
      );
    },
    addWhiteListUserBtnLoading() {
      return this.$store.getters["pinaStore/getterLoadingStatus"](
        "ADD_WHITE_LIST_USER"
      );
    },
    isAddWhiteListUserBtnDisabled() {
      return false;
    },
  },
  mounted() {},
  methods: {
    async deleteUserPlate() {
      this.confirmDelPlateDialog = false;
      let delUserPlateData = this.selectedZone?.zid
        ? await API.deleteWhiteListUser({
            zid: this.selectedPlateToDel.zid,
            id: this.selectedPlateToDel._id,
          })
        : {};
        this.$notify({
        group: "divrt",
        title:
          delUserPlateData ? "Success" : "Error",
        text: delUserPlateData
          ? "User deleted successfully"
          : "Error in deleting user",
        type:
          delUserPlateData ? "success" : "error",
      });
      // this.deleteUserStatusMsg = delUserPlateData?.data?.message
      //   ? delUserPlateData.data.message
      //   : "";
      // this.deleteUserPlateSnackBar = true;
      EventBus.$emit("user-plate-deleted");
    },
    openConfirmDelPlateDialog(plate) {
      this.selectedPlateToDel = Object.assign(
        {},
        this.selectedPlateToDel,
        plate
      );
      this.confirmDelPlateDialog = true;
    },
    onFilterZoneTextClear() {
      this.filterZoneText = "";
    },
    onClickOutside() {
      this.onFilterZoneTextClear();
    },
    getZoneText(item) {
      return item.zcode + " ( " + item.name + " )";
    },
    async addWhiteListUser() {
      this.$store.commit("pinaStore/SET_LOADING_STATUS", {
        property: "ADD_WHITE_LIST_USER",
        pending: true,
      });
      let licencePlate = {
        state: this.licencePlateStateValue,
        regNo: this.licencePlateValue,
      };
      let postData = {
        email: this.emailValue,
        orderType: this.userType,
        vendorId: "",
        startDate: this.startDatetime
          ? format(this.startDatetime, "MM/dd/yyyy" + " " + "hh:mm a")
          : "",
        endDate: this.endDatetime
          ? format(this.endDatetime, "MM/dd/yyyy" + " " + "hh:mm a")
          : "",
        zid: this.zoneSelected,
        zcode: "",
        licensePlate: licencePlate,
        contactNumber: this.phoneNumberValue,
      };
      let addUser = await API.addWhiteListUser(postData);
      this.$notify({
        group: "divrt",
        title:
          addUser?.data?.status && addUser.data.status ? "Success" : "Error",
        text: addUser?.data?.status
          ? "User added successfully"
          : "Error in adding user",
        type:
          addUser?.data?.status && addUser.data.status ? "success" : "error",
      });
      this.$store.commit("pinaStore/SET_LOADING_STATUS", {
        property: "ADD_WHITE_LIST_USER",
        pending: false,
      });
      this.addWhiteListUserDialog = false;
      EventBus.$emit("white-list-user-added");
    },
  },
};
</script>
